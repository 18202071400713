import React from 'react';
import { Disqus } from 'gatsby-plugin-disqus';
import Subscription from '@components/Subscription';
import styled from '@emotion/styled';

import { Template } from '@types';

const ArticleFooter: Template = ({ pageContext }) => {
  const { article, mailchimp } = pageContext;

  return (
    <>
      {mailchimp && article.subscription && <Subscription />}
      <DisqusContainer>
        <Disqus
          config={{
            /* Replace PAGE_URL with your post's canonical URL variable */
            url: `https://fa.aien.me${article.slug}`,
            /* Replace PAGE_IDENTIFIER with your page's unique identifier variable */
            identifier: article.title,
            /* Replace PAGE_TITLE with the title of the page */
            title: article.title,
            language: 'fa',
          }}
        />
      </DisqusContainer>
    </>
  );
};

const DisqusContainer = styled.div`
  margin: 0 auto;
  max-width: 640px;
`;

export default ArticleFooter;
