import styled from '@emotion/styled';
import { PropType } from '../../gatsby-plugin-theme-ui';

const Figcaption = styled.figcaption<PropType>`
  color: ${p => p.theme.colors.grey};
  font-size: ${p => p.theme.sizing.fontSize0};
  text-align: center;
  width: ${p => p.theme.sizing.maxWidthFull};
  padding-top: ${p => p.theme.sizing.spacing3};
`;

export default Figcaption;
