import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import theme, { PropType } from '../../gatsby-plugin-theme-ui';

const OrderedList = styled.ol<PropType>`
  list-style: none;
  counter-reset: list;
  color: ${p => p.theme.colors.articleText};
  position: relative;
  padding: ${p => p.theme.sizing.spacing2} 0 ${p => p.theme.sizing.spacing3}
    ${p => p.theme.sizing.spacing3};
  margin: 0 auto;
  transition: ${p => p.theme.colorModeTransition};
  font-size: ${p => p.theme.sizing.fontSize2};

  width: ${p => p.theme.sizing.maxWidthFull};
  max-width: ${p => p.theme.sizing.maxWidth2XL};

  ${mediaqueries.desktop`
    max-width: 507px;
  `}

  ${mediaqueries.tablet`
    max-width: 486px;
    padding-${theme.isRtl ? 'right' : 'left'}: 0px;
  `};

  ${mediaqueries.phablet`
    padding-${theme.isRtl ? 'right' : 'left'}: 20px;
  `};

  li {
    position: relative;
    padding-bottom: 15px;

    ${mediaqueries.tablet`
      padding-${theme.isRtl ? 'right' : 'left'}: 30px;
    `};

    ${mediaqueries.phablet`
      padding-${theme.isRtl ? 'right' : 'left'}: 30px;
    `};

    p {
      ${mediaqueries.tablet`
        padding: 0;
      `};
    }
  }

  li > :not(ol, ul) {
    display: inline;
  }

  li::before {
    width: 3rem;
    display: inline-block;
    position: absolute;
    color: ${p => p.theme.colors.articleText};
  }

  li::before {
    counter-increment: list;
    content: counter(list, ${p => (p.theme.isRtl ? 'persian' : 'decimal')}) '.';
    font-weight: 600;
    position: absolute;
    ${theme.isRtl ? 'right' : 'left'}: -3rem;
    top: -0.3rem;
    font-size: 2rem;

    ${mediaqueries.tablet`
      ${theme.isRtl ? 'right' : 'left'}: 0;
    `};
  }
`;

export default OrderedList;
