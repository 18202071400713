import styled from '@emotion/styled';
import mediaqueries from '@styles/media';
import { PropType } from '../../gatsby-plugin-theme-ui';

const Cell = styled.td<PropType>`
  border-top: 1px dashed ${p => p.theme.colors.horizontalRule};
  padding: ${p => p.theme.sizing.spacing4} ${p => p.theme.sizing.spacing8};
  word-break: keep-all;
  font-size: ${p => p.theme.sizing.fontSize1};
  background: ${p => p.theme.colors.card};

  ${mediaqueries.desktop`
    padding: ${p => p.theme.sizing.spacing4} ${p => p.theme.sizing.spacing8};
  `}

  ${mediaqueries.tablet`
    font-size: 14px;
  `}
`;

export default Cell;
