import styled from '@emotion/styled';
import mediaqueries from '@styles/media';
import theme from '../../gatsby-plugin-theme-ui';

type PropType = {
  theme: typeof theme;
};

const Blockquote = styled.blockquote<PropType>`
  transition: ${p => p.theme.colorModeTransition};
  margin: ${p => p.theme.sizing.spacing8} auto ${p => p.theme.sizing.spacing10};
  color: ${p => p.theme.colors.articleText};
  font-family: ${p => p.theme.fonts.serif};
  font-style: italic;
  box-shadow: ${p => p.theme.shadows.default};
  background: ${p => p.theme.colors.backgroundAccent};
  transition: all 0.3s ease-out;

  ${mediaqueries.tablet`
    margin: ${p => p.theme.sizing.spacing2} auto ${p =>
    p.theme.sizing.spacing4};
  `};

  & > p {
    font-family: ${p => p.theme.fonts.serif};
    max-width: ${p => p.theme.sizing.maxWidth4XL} !important;
    padding: ${p => p.theme.sizing.spacing10};
    width: 100%;
    margin: 0 auto;
    font-size: ${p => p.theme.sizing.fontSize2};
    line-height: ${p => p.theme.sizing.lineHeightRelaxed};
    font-weight: bold;
    word-spacing: ${p => p.theme.sizing.spacing1};

    &:before,
    &:after {
      font-size: ${p => p.theme.sizing.fontSize5};
    }

    &:before {
      content: open-quote;
    }
    &:after {
      content: close-quote;
    }

    ${mediaqueries.tablet`
      font-size: ${theme.sizing.fontSize2};
      padding: 0 ${theme.sizing.spacing8};
    `};

    ${mediaqueries.phablet`
      font-size: ${theme.sizing.fontSize3};
      padding: 0 ${theme.sizing.spacing8} 0 ${theme.sizing.spacing10};
    `};
  }
`;

export default Blockquote;
