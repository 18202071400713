import styled from '@emotion/styled';
import mediaqueries from '@styles/media';
import { PropType } from '../../gatsby-plugin-theme-ui';

const Paragraph = styled.p<PropType>`
  line-height: ${p => p.theme.sizing.lineHeightRelaxed};
  font-size: ${p => p.theme.sizing.fontSize2};
  color: ${p => p.theme.colors.articleText};
  font-family: ${p => p.theme.fonts.sansSerif};
  transition: ${p => p.theme.colorModeTransition};
  margin: 0 auto ${p => p.theme.sizing.spacing10};
  width: ${p => p.theme.sizing.maxWidthFull};
  max-width: ${p => p.theme.sizing.maxWidth4XL};

  b {
    font-weight: 800;
  }

  ${mediaqueries.desktop`
    max-width: 507px;
  `}

  ${mediaqueries.tablet`
    max-width: 486px;
    margin: 0 auto 25px;
  `};

  ${mediaqueries.phablet`
    padding: 0 20px;
  `};
`;

export default Paragraph;
