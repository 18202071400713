import styled from '@emotion/styled';
import mediaqueries from '@styles/media';
import { PropType } from '../../gatsby-plugin-theme-ui';

const Cell = styled.td<PropType>`
  padding: ${p => p.theme.sizing.spacing4} ${p => p.theme.sizing.spacing8};
  font-size: ${p => p.theme.sizing.fontSize2};
  background: ${p => p.theme.colors.card};

  ${mediaqueries.desktop`
    padding: ${p => p.theme.sizing.spacing4} ${p => p.theme.sizing.spacing8};
  `}

  ${mediaqueries.tablet`
    font-size: ${p => p.theme.sizing.fontSize2};
  `}
`;

export default Cell;
