import React from 'react';
import Highlight, { defaultProps, Language } from 'prism-react-renderer';
import styled from '@emotion/styled';
import { LiveProvider, LiveEditor, LiveError, LivePreview } from 'react-live';
import theme from 'prism-react-renderer/themes/oceanicNext';

import mediaqueries from '@styles/media';
import { PropType } from '../../gatsby-plugin-theme-ui';

const RE = /{([\d,-]+)}/;

function calculateLinesToHighlight(meta) {
  if (RE.test(meta)) {
    const lineNumbers = RE.exec(meta)[1]
      .split(',')
      .map(v => v.split('-').map(y => parseInt(y, 10)));

    return index => {
      const lineNumber = index + 1;
      const inRange = lineNumbers.some(([start, end]) =>
        end ? lineNumber >= start && lineNumber <= end : lineNumber === start,
      );
      return inRange;
    };
  } else {
    return () => false;
  }
}

interface CodePrismProps {
  codeString: string;
  language: Language;
  metastring?: string;
}

const CodePrism: React.FC<CodePrismProps> = ({
  codeString,
  language,
  metastring,
  ...props
}) => {
  const shouldHighlightLine = calculateLinesToHighlight(metastring);

  if (props['live']) {
    return (
      <Container>
        <LiveProvider code={codeString} noInline={true} theme={theme}>
          <LiveEditor style={{ marginBottom: '3px', borderRadius: '2px' }} />
          <LivePreview style={{ fontSize: '18px', borderRadius: '2px' }} />
          <LiveError style={{ color: 'tomato' }} />
        </LiveProvider>
      </Container>
    );
  } else {
    return (
      <Highlight {...defaultProps} code={codeString} language={language}>
        {({ className, tokens, getLineProps, getTokenProps }) => {
          return (
            <div style={{ overflow: 'auto' }}>
              <pre className={className} style={{ position: 'relative' }}>
                {tokens.map((line, index) => {
                  const { className } = getLineProps({
                    line,
                    key: index,
                    className: shouldHighlightLine(index)
                      ? 'highlight-line'
                      : '',
                  });

                  return (
                    <div key={index} className={className}>
                      <span className="number-line">{index + 1}</span>
                      {line.map((token, key) => {
                        const { className, children } = getTokenProps({
                          token,
                          key,
                        });

                        return (
                          <span key={key} className={className}>
                            {children}
                          </span>
                        );
                      })}
                    </div>
                  );
                })}
              </pre>
            </div>
          );
        }}
      </Highlight>
    );
  }
};

export default CodePrism;

const Container = styled.div<PropType>`
  overflow: scroll;
  width: 100%;
  max-width: ${p => p.theme.sizing.maxWidth5XL};
  margin: 0 auto;
  font-size: ${p => p.theme.sizing.fontSize0};
  margin: ${p => p.theme.sizing.spacing4} auto ${p => p.theme.sizing.spacing10};
  border-radius: ${p => p.theme.sizing.borderRadiusHeavy};
  font-family: ${p => p.theme.fonts.monospace} !important;

  textarea,
  pre {
    text-align: left;
    padding: 16px !important;
    font-family: ${p => p.theme.fonts.monospace} !important;
  }

  ${mediaqueries.desktop`
      left: -26px;
    `};

  ${mediaqueries.tablet`
    max-width: 526px;
    left: 0;

    textarea,
    pre {
      padding: 20px !important;
    }
  `};

  ${mediaqueries.phablet`
    border-radius: ${p => p.theme.sizing.borderRadiusNone};
    margin: 0 auto 25px;
    overflow-y: auto;
    width: unset;
    max-width: unset;
    min-width: 100%;
    position: relative;
  `};
`;
