import styled from '@emotion/styled';
import mediaqueries from '@styles/media';

import theme, { PropType } from '../../gatsby-plugin-theme-ui';

const UnorderedList = styled.ul<PropType>`
  list-style: none;
  counter-reset: list;
  color: ${p => p.theme.colors.articleText};
  position: relative;
  padding: ${p => p.theme.sizing.spacing2} 0 ${p => p.theme.sizing.spacing3}
    ${p => p.theme.sizing.spacing3};
  transition: ${p => p.theme.colorModeTransition};
  margin: 0 auto;
  font-size: ${p => p.theme.sizing.fontSize2};

  width: ${p => p.theme.sizing.maxWidthFull};
  max-width: ${p => p.theme.sizing.maxWidth2XL};

  ${mediaqueries.desktop`
    max-width: 507px;
  `}

  ${mediaqueries.tablet`
    max-width: 486px;
    padding-${theme.isRtl ? 'right' : 'left'}: 0px;
  `};

  ${mediaqueries.phablet`
    padding-${theme.isRtl ? 'right' : 'left'}: 20px;
  `};

  li {
    position: relative;
    padding-bottom: 15px;

    ${mediaqueries.tablet`
      padding-${theme.isRtl ? 'right' : 'left'}: 30px;
    `};

    ${mediaqueries.phablet`
      padding-${theme.isRtl ? 'right' : 'left'}: 30px;
    `};

    p {
      ${mediaqueries.tablet`
        padding: 0;
      `};
    }
  }

  li > :not(ol, ul) {
    display: inline;
  }

  li::before {
    width: 3rem;
    display: inline-block;
    position: absolute;
    color: ${p => p.theme.colors.articleText};
  }

  li::before {
    content: '';
    position: absolute;
    ${theme.isRtl ? 'right' : 'left'}: -30px;
    top: 8px;
    height: 8px;
    width: 8px;
    background: ${p => p.theme.colors.articleText};

    ${mediaqueries.tablet`
      ${theme.isRtl ? 'right' : 'left'}: 0;
    `};
  }
`;

export default UnorderedList;
